<!-- 报警 -->
<template>
    <div class="">
        <cards cardsTit='报警数量' :cardsNum="300">
            <template slot="cardsConter">
                <div ref="chart1" style="width: 100%;height: 100%;"></div>
            </template>
        </cards>
    </div>
</template>

<script>
import cards from "../../components/cards.vue";
import * as echarts from 'echarts';
export default {
    props:{
        initPomes:{
            type:Object,
            default:{}
        }
    },
    components: {
        cards
    },
    data() {
        return {
            chart: null,
            options: {
                tooltip: {
                    trigger: 'axis', // 触发类型，默认数据触发，可选为：'item'、'axis'  
                    backgroundColor: 'rgba(#00c9ff, 1)', // 设置背景颜色为白色，透明度为0.8
                    textStyle: {
                        color: '#fff' // 设置字体颜色为白色  
                        // 你还可以在这里添加其他文本样式设置，比如 fontSize, fontFamily 等  
                    },
                },
                xAxis: {
                    data: ["今天报警数量", "未处理报警数量"],
                    axisLine: {
                        lineStyle: {
                            color: '#00c9ff' // X轴轴线颜色  
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            color: '#00c9ff' // X轴刻度颜色  
                        }
                    },
                    axisLabel: {
                        color: '#00c9ff' // X轴标签颜色  
                    }
                },
                grid: {
                    // top:'15%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value',
                    // interval: 2, // 设置 Y 轴刻度的间隔为 20
                    splitLine: {
                        show: true, // 确保网格线显示  
                        lineStyle: {
                            color: '#00c9ff', // 设置网格线颜色为蓝色  
                            width: 1, // 设置网格线宽度  
                            type: 'solid' // 设置网格线类型为实线  
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#00c9ff' // Y轴轴线颜色  
                        }
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#00c9ff', // Y轴刻度颜色  
                        }
                    },
                    axisLabel: {
                        color: '#00c9ff' // Y轴标签颜色  
                    }
                },
                series: [{
                    type: 'bar',
                    barMaxWidth: '20%',//宽度
                    data: [
                        {
                            value: 30,
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00baff' // 0% 处的颜色  
                                    }, {
                                        offset: 0.5, color: '#0170fd' // 50% 处的颜色  
                                    }, {
                                        offset: 1, color: '#003cf6' // 100% 处的颜色  
                                    }]
                                },
                                borderRadius: [20, 20, 0, 0]
                            }
                        }, {
                            value: 3,
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#c7a402' // 0% 处的颜色  
                                    }, {
                                        offset: 0.5, color: '#d58d15' // 50% 处的颜色  
                                    }, {
                                        offset: 1, color: '#dc8422' // 100% 处的颜色  
                                    }]
                                },
                                borderRadius: [20, 20, 0, 0]
                            }
                        }]
                }]
            },
        }
    },
    watch:{
        initPomes:{
            handler(newData){
                this.options.series[0].data[0].value = newData.WarningNum
                this.options.series[0].data[1].value = newData.UntreatedWarningNum
                this.$nextTick(()=>{
                    this.initChart()
                })
            },deep:true
        }
    },
    methods: {
        initChart() {
            // 基于准备好的dom，初始化echarts实例  
            this.chart = echarts.init(this.$refs.chart1);
            // 使用刚指定的配置项和数据显示图表。  
            this.chart.setOption(this.options);
        }
    },
    beforeDestroy() {
        if (!this.chart) return
        // 在组件销毁前，确保图表也被销毁  
        this.chart.dispose();
    }
}
</script>
<style lang="scss">
/* @import url(); 引入css类 */
</style>