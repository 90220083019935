<template>
    <div class="showCards">
        <div class="showCards-item" v-for="item, index in list" :key="index">
            <div class="showCards-item-L">
                <img :src="item.ImgUrl" style="width: 100%;height: 100%;" />
            </div>
            <div class="showCards-item-R">
                <div class="showCards-item-title">
                    {{ item.tit }}
                </div>
                <div class="showCards-conter">
                    <div class="showCards-conter-left">
                        {{ item.num }}
                        <!-- <span style="font-size: 14px;">{{ item.unit }}</span> -->
                    </div>
                    <div class="showCards-conter-right" v-show="item.show">
                        <el-link type="primary" @click="showDetails(item)">{{ ['未处理报警','今日报警'].includes(item.tit)? '去查看' : '详情' }}
                            <!-- <i class="el-icon-view el-icon--right"></i> -->
                        </el-link>
                    </div>
                </div>
            </div>
        </div>
        <my-Modalbox :visible.sync="visible" :titleBox="titleBox" minHeihts="auto" isheight="50%">
            <template slot="modalConter" v-if="titleBox == '专属客服'">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="T_name" label="专属工程师">
                    </el-table-column>
                    <el-table-column prop="T_phone" label="联系方式">
                    </el-table-column>
                </el-table>
            </template>
            <template slot="modalConter" v-else>
                <my-tables ref="tableRef" :headers="columns" :requestApi="requestApi" :initParam="initParam"
                    @checkboxFun="checkboxFun" :checkbox="false" :indexType="true"></my-tables>
            </template>
        </my-Modalbox>
    </div>
</template>

<script>
import { record } from "@/api/workbench"

import { EventBus } from '@/main.js';
import { formatDate } from '@/utils/Times.js'
export default {
    props: {
        initPomes: {
            type: Object,
            default: {}
        }
    },
    watch: {
        initPomes: {
            handler(newData) {
                console.log('电话', newData)
                this.list[0].num = newData.WarningNum
                this.list[1].num = newData.UntreatedWarningNum
                this.list[2].num = newData.NoticeRecord.Sms
                this.list[3].num = newData.NoticeRecord.Phone
                this.list[4].num = newData.NoticeRecord.Admin || [] .length
                EventBus.$emit('my-event', newData);
            }, deep: true
        }
    },
    data() {
        return {
            titleBox: '',

            requestApi: null,
            initParam: {
                page: 1,
                pageSize: 10,
            },

            columns: [
                { key: 'T_model', label: '名称' },
                { key: 'T_remarks', label: '用途' },
            ],
            visible: false,
            list: [{
                tit: '今日报警',
                num: 0,
                unit: '（条）',
                ImgUrl: require('../../assets/img/btn-icon-2.png'),
                show: true,
            }, {
                tit: '未处理报警（近40天）',
                num: 0,
                unit: '（条）',
                ImgUrl: require('../../assets/img/btn-icon-3.png'),
                show: true
            }, {
                tit: '本月通知（短信）',
                num: 0,
                unit: '（条）',
                ImgUrl: require('../../assets/img/btn-icon-4.png'),
                show: true,
                requestApi: record,
                initParam: {
                    T_ntype: 1,
                    page: 1,
                    page_z: 5
                },
                columns: [
                    { key: 'T_Notice', label: '通知电话' },
                    { key: 'T_tp_name', label: '通知类型' },
                    { key: 'CreateTime', label: '发送时间' },
                    { key: 'T_Remark', label: '通知内容' },
                ]
            }, {
                tit: '本月通知（电话）',
                num: 0,
                unit: '（条）',
                ImgUrl: require('../../assets/img/btn-icon-5.png'),
                show: true,
                requestApi: record,
                initParam: {
                    T_ntype: 2,
                    page: 1,
                    page_z: 5
                },
                columns: [
                    { key: 'T_Notice', label: '通知电话' },
                    { key: 'T_tp_name', label: '通知类型' },
                    { key: 'CreateTime', label: '发送时间' },
                    { key: 'T_Remark', label: '通知内容' },
                ]

            }, {
                tit: '专属客服',
                num: 0,
                unit: '（位）',
                ImgUrl: require('../../assets/img/btn-icon-2.png'),
                show: true,
            }],
            tableData: []
        }
    },
    methods: {
        showDetails(item) {
            console.log('item', item)
            if (['本月通知（电话）', '本月通知（短信）'].includes(item.tit)) {
                this.visible = true
                this.titleBox = item.tit == '本月通知（电话）' ? '电话' : '短信'
                this.requestApi = item.requestApi
                this.initParam = item.initParam
                this.columns = item.columns
                this.$nextTick(() => {
                    this.$refs.tableRef.callPropFunction()
                })
            } else if (item.tit == '专属客服') {
                this.visible = true
                this.titleBox = item.tit
                this.tableData = item.Admin
            }else{
                const obj = {
                    T_handle:item.tit=='今日报警'?1:3,
                    Time_start: this.getPreviousDaysMidnight(40),
                    Time_end: formatDate((new Date()).getTime())
                }
                console.log('223',obj)
                // this.$router.replace('/intelligentsystem/alarmData')
                this.$router.push({
                    name:'intelligentsystemalarmData',//'callthepolice'
                    query: obj
                })
            }
        },
        getPreviousDaysMidnight(days) {  
            // 创建一个Date对象表示当前时间  
            let now = new Date();  
            // 设置时间为当前日期的零点  
            now.setHours(0, 0, 0, 0); // 分别设置小时、分钟、秒、毫秒为0  
            // 从当前日期中减去指定天数的毫秒数  
            // 一天有 24 * 60 * 60 * 1000 毫秒  
            now.setTime(now.getTime() - days * 24 * 60 * 60 * 1000);  
            return formatDate(now);  
        },
        checkboxFun(data) {
            console.log('勾选', data)
        }
    }
}
</script>
<style scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}

/* 设置 el-table 的背景色为透明 */
::v-deep .el-table {
    background-color: transparent !important;
}

/* 设置 el-table 的表头背景色 */
::v-deep .el-table__header-wrapper th {
    background-color: rgba(133, 170, 233, 0.08) !important;
    /* 将 #your-color 替换为你想要的颜色 */
    /* font-size: 16px; */
    color: #03aff6;
}

::v-deep .el-table tr {
    background-color: transparent !important;
}

/* 设置表格行的背景色为透明 */
::v-deep .el-table__body tr {
    background-color: transparent !important;
    color: #03aff6;
}

/* 如果需要，也可以设置鼠标悬停时的行背景色为透明 */
::v-deep .el-table__body tr:hover>td {
    background-color: rgba(133, 170, 233, 0.08) !important;
}

::v-deep .el-table__cell {
    border-bottom: none !important;
}


/**去掉table底部的横线 */
::v-deep .el-table::before {
    height: 0;
}


::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 1px solid #03aff6;
}
  
/* 修改勾选框的伪元素颜色（如果需要） */  
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {  
    border-color:#03aff6; /* 勾选标记的颜色 */  
}
::v-deep .el-table__cell .cell {
    text-align: center !important;
}
</style>
<style lang="scss">
@import url('../../assets/scss/showCards.scss');
</style>