// 登录接口
import {
	axios
} from '../utils/http.js'

const WorkApi = {
	workList: '/v3/User/Home',//权限管理列表
	flowpool: '/v3/Flow_Pool',//流量池
	downBill: '/v3/Company/Bill_Excel',//账单下载
	record: '/v3/WarningSend/List',//账单下载
	
}
//账单下载
export function record(data) {
	return axios({
		url: WorkApi.record,
		method: 'post',
		data:data
	})
}
//账单下载
export function downBill(data) {
	return axios({
		url: WorkApi.downBill,
		method: 'post',
		data:data
	})
}
//流量池
export function flowpool(data) {
	return axios({
		url: WorkApi.flowpool,
		method: 'post',
		data:data
	})
}
//权限树选择
export function workList(data) {
	return axios({
		url: WorkApi.workList,
		method: 'post',
		data:data
	})
}
