<!-- 圆环 -->
<template>
    <div>
        <div class="ring">
            <div class="yzzb_zs">
                <span style="font-weight: bold;font-size: 20px;">￥:{{ ringNum }}</span>
                <span style="font-size: 14px;">{{ ringTit }}</span>
                <div class="yzzb_handle">
                    <span @click="showModel">充值</span>
                    <span @click="billDownload = true">账单</span>
                </div>
            </div>
            <img src="../../assets/img/icon-yuan-1.png" class="bg1">
            <img src="../../assets/img/icon-yuan-2.png" class="bg2">
        </div>
        <el-dialog title="账单下载" :visible.sync="billDownload" center :append-to-body="true" width="30%">
           <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <el-date-picker v-model="T_month" :picker-options="pickerOptions" value-format="yyyy-MM" type="month"
						placeholder="请选择时间" :clearable="false">
					</el-date-picker>
                <div style="margin-top: 50px;">
                    <el-button @click="billDownload = false">取消</el-button>
                    <el-button type="primary" @click="onSubmit">账单下载</el-button>
                </div>
           </div>
        </el-dialog>
        <el-dialog title="充值" :visible.sync="visible" center :append-to-body="true">
            <div style="min-width:300px;">
                <el-steps :active="active" finish-status="success" align-center>
                    <el-step title="充值金额"></el-step>
                    <el-step title="支付订单"></el-step>
                    <el-step title="支付结果"></el-step>
                </el-steps>
                <div class="dia-item" v-if="active == 0"
                    style="display: flex;flex-direction: column;align-items: center;">
                    <el-input v-model="Total" placeholder="请输入充值金额" style="margin-top: 50px;width: 70%;"></el-input>
                    <div class="cardList">
                        <div class="cardList-item" v-for="item, index in list" :key="index" @click="Total = item">
                            <span>{{
                    item }}</span><span>元</span></div>
                    </div>
                    <div style="margin-top: 50px;">
                        <el-button @click="visible = false">放弃支付</el-button>
                        <el-button type="primary" @click="nextFun">下一步</el-button>
                    </div>
                </div>
                <div class="dia-item" v-if="active == 1"
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                    <vue-qr :text="imgSrc" :size="200"></vue-qr>
                    <div style="margin-top: 30px; font-size: 20px">请使用微信扫一扫</div>
                </div>
                <div class="dia-item" v-if="active == 3"
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                    <el-result icon="success" data-aos="zoom-in" :data-aos-delay="0" title="余额充值" subTitle="恭喜你，余额充值成功"
                        style="color: #5fc5ff;">
                        <template slot="extra">
                            <el-button type="primary" size="medium" @click="Iseefun">我知道了</el-button>
                        </template>
                    </el-result>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import vueQr from 'vue-qr'
import { wxQr, wxState } from "@/api/wxpay";
import {downBill} from "@/api/workbench.js"
import Axios from 'axios'
import fileDownload from 'js-file-download';

export default {
    components: {
        vueQr
    },
    props: {
        ringTit: {
            default: '',
            type: String,
        },
        ringNum: {
            default: 0,
            type: Number,
        }
    },
    data() {
        return {
            T_month:'',
            pickerOptions: { //限制选择当月之后的月份
				disabledDate: time => {
					return time.getTime() > Date.now();
				},
			},
            imgSrc: '',
            list: [10, 50, 100, 500, 1000, 2000],
            Total: '',
            active: 0,
            billDownload: false,
            visible: false,
            times: null,
        }
    },
    watch: {
        visible(newData) {
            if (!newData) clearInterval(this.times)
        }
    },
    beforeDestroy() {
        clearInterval(this.times)
    },
    methods: {
        onSubmit() {
			const loading = this.$loading({
				lock: true,
				text: '正在整理数据，请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let that = this
			downBill({
				T_month: this.T_month
			}).then(res => {
				if (res.data.Code == 200) {
					let resIt = res.data.Data
					if (res.data.Data != '') {
						let downName = resIt.split('/')
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							that.billDownload = false
							fileDownload(resBlob.data, downName[downName.length - 1]);
						});
					} else {
						this.$message.error('哎呀，缺少下载地址呀')
					}
				}
                loading.close()
			})
		},
        Iseefun() {//我知道了
            this.active = 0
            this.visible = false
            this.Total = ''
        },
        async nextFun() {
            const that = this
            if (this.Total) {
                wxQr({ Total: this.Total }).then(reslut => {
                    if (reslut.data.Code == 200) {
                        if (reslut.data.Code == 200) {
                            this.imgSrc = reslut.data.Data.CodeUrl
                            this.active = 1
                            this.times = setInterval(() => {
                                that.wxStateApi(reslut.data.Data.OrderNo)
                            }, 1000);
                        }
                    }
                })
            } else {
                this.$message.error('充值金额不能为空')
                return
            }
        },
        //获取支付状态
        wxStateApi(val) {
            const that = this
            wxState({ OrderNo: val }).then(res => {
                if (res.data.Data.T_status != '') {
                    that.active = 3
                    clearInterval(that.times)
                }
            })
        },
        showModel() {
            this.visible = true
            this.active = 0
            this.Total = ''

        }
    }
}
</script>
<style scoped>
::v-deep .el-result .icon-success {
    fill: #409eff;
}

::v-deep .el-step__head.is-success {
    border-color: #409eff;
}

::v-deep .el-result__title p {
    color: #409eff;
}

::v-deep .el-result__subtitle p {
    color: #409eff;
}

::v-deep .el-step__title.is-success {
    color: #409eff;
}

::v-deep .el-input__inner {
    background-color: #183d7d !important;
    border: 1px solid #24509b;
    color: #5fc5ff;
    text-align: center;
}

::v-deep .el-form-item__label {
    color: #5fc5ff !important;

}

::v-deep .el-input__inner:focus {
    border: 1px solid #24509b;
}



::v-deep .el-dialog__header {
    background: #183d7d;
    color: #5fc5ff;
}

::v-deep .el-dialog__title {
    color: #5fc5ff;
}

::v-deep .el-step__title.is-process {
    color: #5fc5ff;
}

::v-deep .el-step__icon.is-text {
    border-color: #5fc5ff;
    color: #5fc5ff;
}

::v-deep .el-step__line {
    position: absolute;
    border-color: inherit;
    background-color: #5fc5ff;
}

::v-deep .el-step__head.is-process {
    border-color: #5fc5ff;
    color: #5fc5ff;
}

::v-deep .el-step__icon {
    background: #183d7d;
}

::v-deep .el-dialog--center .el-dialog__body {
    color: #5fc5ff;
    background: #183d7d;

}
</style>
<style lang="scss">
@import url('../../assets/scss/ring.scss');
</style>