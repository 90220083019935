<template>
    <div class="home">
        <div class="home-showCards">
            <showCards :initPomes="initPomes"></showCards>
        </div>
        <div class="home-conter">
            <alarm :initPomes="initPomes" data-aos="fade-right" data-aos-delay="0"></alarm>
            <ring ringTit="余额" :ringNum="ringNum" data-aos="zoom-in" data-aos-delay="0"></ring>
            <notification :initPomes="initPomes" data-aos="fade-left" data-aos-delay="0"></notification>
        </div>
        <div @click="b">
            退出
        </div>
    </div>
</template>

<script>

import showCards from "./showCards.vue";


import ring from "./ring.vue";
import alarm from "./alarm.vue";
import notification from "./notification.vue";
import engineer from "./engineer.vue";



import {
	workList,
} from "@/api/workbench.js"
export default {

    components: {
        showCards,ring, alarm, notification,engineer
    },
    data() {
        return {
            ringNum:0,
            initPomes:{},
        }
    },
    mounted(){
     this.getWorkListApi()   
    },
    methods:{
        b(){
            this.$router.replace('/index')
        },
        getWorkListApi() {
			workList({}).then(res => {
				if (res.data.Code === 200) {
					this.ringNum =  res.data.Data.Balance
                    this.initPomes = res.data.Data
				}
			})
		},
    }
      
}
</script>
<style lang="scss">
@import url('../../assets/scss/myinfo.scss');
</style>